(function () {
  document.addEventListener('DOMContentLoaded', function () {
    function onClick(event) {
      event.preventDefault();
      const image = this.querySelector('img');
      if (!image) return;
      const instance = basicLightbox.create(`<img src="${image.src}" />`);
      instance.show();
    }

    for (const el of document.querySelectorAll('a.lightbox')) {
      el.addEventListener('click', onClick);
    }
  });
})();